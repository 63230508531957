<template>
  <div class="wrapper" style="width: 100%">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="btn-back">
      <div></div>
      <el-button type="info" @click="$router.back()" size="mini">返回</el-button>
    </div>
    <div class="title">基本信息</div>
    <el-descriptions direction="vertical" :column="4" border>
      <el-descriptions-item label="案件编号" :span="1" label-class-name="my-label">{{ detail.no }}</el-descriptions-item>
      <el-descriptions-item label="申请人姓名（单位）" :span="1" label-class-name="my-label">{{ detail.apply_username }}</el-descriptions-item>
      <el-descriptions-item label="证件号" :span="1" label-class-name="my-label">{{ detail.apply_idcard }}</el-descriptions-item>
      <el-descriptions-item label="申请人手机号码" :span="1" label-class-name="my-label">{{ detail.apply_mobile }}</el-descriptions-item>
      <el-descriptions-item label="申请人地址" :span="4" label-class-name="my-label">{{ detail.apply_address }}</el-descriptions-item>
      <el-descriptions-item label="对方姓名（单位）" :span="1" label-class-name="my-label">{{ detail.opposite_username }}</el-descriptions-item>
      <el-descriptions-item label="对方手机号码" :span="1" label-class-name="my-label">{{ detail.opposite_mobile }}</el-descriptions-item>
      <el-descriptions-item label="对方地址" :span="1" label-class-name="my-label">{{ detail.opposite_address }}</el-descriptions-item>

      <el-descriptions-item label="纠纷类型" :span="1" label-class-name="my-label">{{ detail.dispute_type }}</el-descriptions-item>
      <el-descriptions-item label="纠纷内容" :span="2" label-class-name="my-label">{{ detail.mediate_content }}</el-descriptions-item>
      <el-descriptions-item label="处理区域" :span="1" label-class-name="my-label">{{ handle_area }}</el-descriptions-item>

      <el-descriptions-item label="案件资料" :span="4" label-class-name="my-label">
        <el-image
          style="width: 60px; height: 60px; margin-right: 5px"
          v-for="(item, i) in detail.mediate_files"
          :key="i"
          :src="item.file_name"
          :preview-src-list="[item.file_name]"
        >
        </el-image>
      </el-descriptions-item>
    </el-descriptions>
    <div class="title">调解员信息</div>
    <el-descriptions direction="vertical" :column="4" border>
      <el-descriptions-item label="姓名" :span="1" label-class-name="my-label">{{ law_people?.username }}</el-descriptions-item>
      <!-- <el-descriptions-item label="头像" :span="1" label-class-name="my-label">
        <el-image style="width: 60px; height: 60px; margin-right: 5px" :src="law_people?.face" :preview-src-list="[law_people?.face]"> </el-image>
      </el-descriptions-item> -->
      <el-descriptions-item label="身份等级" :span="1" label-class-name="my-label">{{ law_people?.level_dsc }}</el-descriptions-item>
      <el-descriptions-item label="工作地址" :span="1" label-class-name="my-label">{{ law_people?.work_address }}</el-descriptions-item>
      <!-- <el-descriptions-item label="自我介绍" :span="4" label-class-name="my-label">{{ law_people?.intro }}</el-descriptions-item> -->
    </el-descriptions>
    <div class="title">调解结果</div>
    <el-descriptions direction="vertical" :column="3" border>
      <el-descriptions-item label="调解结果" :span="1" label-class-name="my-label">
        <el-tag type="success" size="small" v-if="detail.is_complete == 0">{{ detail.is_complete_dsc }}</el-tag>
        <el-tag type="primary" size="small" v-if="detail.is_complete == 10">{{ detail.is_complete_dsc }}</el-tag>
        <el-tag type="danger" size="small" v-if="detail.is_complete == 20">{{ detail.is_complete_dsc }}</el-tag>
        <el-tag type="warning" size="small" v-if="detail.is_complete == 30">{{ detail.is_complete_dsc }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="调解状态" :span="1" label-class-name="my-label">{{ detail.handle_status_dsc }}</el-descriptions-item>
      <el-descriptions-item label="调解描述" :span="1" label-class-name="my-label">{{ detail.tiaojie_desc }}</el-descriptions-item>
      <el-descriptions-item label="附件" :span="2" label-class-name="my-label">
        <!-- <UploadImgs v-if="detail.handle_status !== 30" :background_images.sync="detail.tiaojie_attachment"></UploadImgs> -->
        <el-image
          style="width: 60px; height: 60px; margin-right: 5px"
          v-for="(item, i) in detail.tiaojie_attachment"
          :key="i"
          :src="item.url"
          :preview-src-list="[item.url]"
        >
        </el-image>
      </el-descriptions-item>
    </el-descriptions>
    <!-- <div class="submit" v-if="detail.handle_status !== 30">
      <div></div>
      <el-button type="primary" size="small" @click="save">保存</el-button>
    </div> -->

    <div style="height: 80px"></div>
  </div>
</template>

<script>
import { detailAPI } from './api'
import UploadImgs from '@/components/uploader/uploader-imgs.vue'
export default {
  name: 'Detail',
  // components: { UploadImgs },
  data() {
    return {
      detail: {}
    }
  },
  computed: {
    law_people() {
      return this.detail.law_people
    },
    handle_area() {
      let str = ''
      if (this.detail.handle_area_detail) {
        const { district_area_name, province_area_name, city_area_name, county_area_name, town_area_name, village_area_name } =
          this.detail.handle_area_detail
        const arr = [province_area_name, city_area_name, county_area_name, town_area_name, village_area_name]
        arr.forEach((item, index) => {
          if (item) {
            str += item
          }
        })
        // str = province_area_name + city_area_name + county_area_name + town_area_name + village_area_name
      }
      return str
    }
  },
  mounted() {
    this.getDetail()
  },

  methods: {
    goBack() {
      this.$router.back()
    },

    async getDetail() {
      this.detail = await detailAPI(this.$route.params.id)
    },
    save() {}
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  .btn-back {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #fafafa;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .title {
    margin: 10px 0;
    font-weight: 600;
  }
  .submit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
}
</style>
