import { http } from '@/http/axios.js'
// 列表
export function getListAPI(params) {
  return http({
    url: '/company/mediate-case/list',
    method: 'get',
    params
  })
}

export function createAPI(data) {
  return http({
    url: '/company/mediate-case/add',
    method: 'post',
    data
  })
}

export function editAPI(data) {
  return http({
    url: '/company/mediate-case/edit',
    method: 'post',
    data
  })
}

export function delAPI(id) {
  return http({
    url: '/company/mediate-case/del',
    method: 'post',
    data: { id }
  })
}

export function detailAPI(id) {
  return http({
    url: '/company/mediate-case/detail',
    method: 'get',
    params: { id }
  })
}

export function modifyIsPublishAPI(data) {
  return http({
    url: '/company/mediate-case/modifyIsPublish',
    method: 'post',
    data
  })
}
// 受理
export function acceptAPI(data) {
  return http({
    url: '/company/mediate-case/accept',
    method: 'post',
    data
  })
}
// 完成
export function completeAPI(data) {
  return http({
    url: '/company/mediate-case/complete',
    method: 'post',
    data
  })
}
// 指派
export function designateAPI(data) {
  return http({
    url: '/company/mediate-case/designate',
    method: 'post',
    data
  })
}
